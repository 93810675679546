<template>
  <md-avatar class="md-large">
    <img v-if="path" :src="getFile(path)" :alt="alt">
    <img v-else src="@/assets/logo-arene.png" :alt="alt">
  </md-avatar>
</template>

<script>
import staticFileMixin from '@/mixins/static-file-mixin';

export default {
  name: 'ImageItem',
  props: {
    path: {
      type: String,
      required: false,
    },
    alt: {
      type: String,
      required: false,
    },
  },
  mixins: [staticFileMixin],
};
</script>
