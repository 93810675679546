<template>
  <label>
    <span class="md-button md-raised md-accent md-theme-default">
      <span class="md-ripple">
        <md-icon>upload_file</md-icon>
        <span class="md-button-content">
          <slot/>
        </span>
      </span>
    </span>
    <input type="file" @change="pickFile"/>
  </label>
</template>

<script>
export default {
  name: 'UploadButton',
  methods: {
    pickFile(event) {
      this.$emit('filePick', event.target.files[0]);
    },
  },
};
</script>

<style scoped>

input[type="file"] {
  display: none;
}

</style>
