<template>
  <div>
    <transition name="fade">
      <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false"
                 :md-active.sync="isActivated">
        <md-dialog-content>
          <md-card-area md-inset>
            <md-card-header>
              <div class="md-headline">Participants</div>
            </md-card-header>

            <md-card-content>
              <md-autocomplete v-model="value" :md-options="users" @md-changed="getUsers"
                               @md-opened="getUsers" @md-selected="addParticipant">
                <label>Utilisateurs disponibles</label>
                <template slot="md-autocomplete-item" slot-scope="{item}">
                  {{ item.email }}
                </template>
                <template slot="md-autocomplete-empty" slot-scope="{term}">
                  pas de résultat pour: {{ term }}
                </template>
              </md-autocomplete>

              <md-list>
                <div class="md-layout md-alignment-bottom-center"
                     v-for="participant in participants"
                     :key="participant.id">
                  <div class="md-size-70 md-layout-item">
                    <span>{{ participant.name }}</span><br>
                    <span>{{ participant.email }}</span><br>
                  </div>
                  <md-field class="no-margin md-layout-item md-size-30">
                    <md-select md-dense v-model="participant.pivot.status">
                      <md-option :value="eventStatus.paid">Payé</md-option>
                      <md-option :value="eventStatus.unpaid">Impayé</md-option>
                      <md-option :value="eventStatus.cancelled">Annulé</md-option>
                    </md-select>
                  </md-field>
                </div>
              </md-list>
            </md-card-content>

          </md-card-area>

          <md-card-actions md-alignment="space-between">
            <div>
              <md-button class="md-accent" @click="close">Annuler</md-button>
            </div>
            <div>
              <md-button class="md-primary" @click="save">Valider</md-button>
            </div>
          </md-card-actions>
        </md-dialog-content>
      </md-dialog>
    </transition>
    <md-button class="md-raised md-primary" @click="isActivated = true">
      <md-icon>people</md-icon>
      Inscrits
    </md-button>
    <md-tooltip md-direction="right">
      Cliquer pour voir les gens inscrit à cet événement
    </md-tooltip>
  </div>
</template>
<script>

import EventService from '@/components/event/EventService';
import UserService from '@/components/user/UserService';
import eventStatus from '@/components/event/event-status';

export default {
  name: 'ParticipantsList',
  data: () => ({
    isActivated: false,
    value: null,
    users: [],
    eventStatus,
    participants: [],
  }),
  props: {
    eventId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    getParticipants() {
      return this.participants.reduce((acc, user) => ({
        ...acc,
        [user.id]: { status: user.pivot.status },
      }), {});
    },
  },
  methods: {
    getUsers(searchTerm) {
      if (typeof searchTerm === 'string' || searchTerm === undefined) {
        this.users = UserService.searchByEmail(searchTerm, this.participants);
      }
    },
    addParticipant(participant) {
      // eslint-disable-next-line no-param-reassign
      participant.pivot = { status: eventStatus.unpaid };
      this.participants.push(participant);
      this.value = '';
    },
    save() {
      this.$emit('save', this.getParticipants);
      this.isActivated = false;
    },
    close() {
      this.isActivated = false;
      this.$emit('closed');
    },
  },
  async mounted() {
    this.participants = await EventService.getParticipants(this.eventId);
  },
};

</script>

<style scoped>

.no-margin {
  margin: 0 !important;
}

</style>
