import http from '@/http/token-http';
import APIFileService from '@/service/APIFileService';

class EventService extends APIFileService {
  constructor() {
    super('/events');
    this.files = ['image', 'result'];
  }

  // eslint-disable-next-line class-methods-use-this
  buildFormData(object) {
    const formData = new FormData();
    if (object.image) {
      formData.append('image', object.image);
    }
    if (object.result) {
      formData.append('result', object.result);
    }
    formData.append('name', object.name);
    formData.append('description', object.description);
    formData.append('date', object.date);
    formData.append('game_id', object.game_id);
    formData.append('price', object.price);
    formData.append('nb_place', object.nb_place);

    return formData;
  }

  // eslint-disable-next-line camelcase
  searchByFilter({ name, game_id }) {
    return http.get(this.resource, {
      params: {
        like: `name,${name}`,
        game_id,
        page: 1,
      },
    })
      .then((res) => res.data);
  }

  // eslint-disable-next-line class-methods-use-this
  async fromUser(userId) {
    return http.get(`/users/${userId}/events`)
      .then((res) => res.data);
  }

  getParticipants(id) {
    return http.get(`${this.resource}/${id}/users`)
      .then((res) => res.data);
  }
}

export default new EventService();
