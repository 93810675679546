<template>
  <div>
    <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false"
               :md-active.sync="isModalVisible">

      <md-dialog-content>
        <validator v-slot="{ handleSubmit }">
          <form novalidate class="md-layout" @submit.prevent="handleSubmit(onSubmit)">
            <div>
              <span class="md-title">{{ title }}</span>

              <div>
                <div class="md-layout">
                  <div class="md-layout-item btn-wrapper">
                    <upload-button @filePick="object.result = $event">Score</upload-button>
                    <md-tooltip md-direction="left">
                      Cliquer ici pour envoyer le résultat
                    </md-tooltip>
                  </div>

                  <div class="md-layout-item btn-wrapper" v-if="object.result_path">
                    <md-button @click="checkResults" class="md-raised md-accent">
                      <md-icon>emoji_events</md-icon>
                      Voir
                    </md-button>
                    <md-tooltip md-direction="bottom">
                      Cliquer pour voir les résultats
                    </md-tooltip>
                  </div>

                  <div v-if="object.id" class="md-layout-item btn-wrapper">
                    <participants-list :event-id="object.id" @save="addUsers"
                                       @closed="disabled = false"
                                       @click.native="preventAction"/>
                  </div>
                </div>
                <rule name="nom" rules="required" v-slot="{errors}">
                  <md-field>
                    <label>Nom</label>
                    <md-input name="name" v-model="object.name"/>
                    <span class="input-error">{{ errors[0] }}</span>
                  </md-field>
                </rule>

                <div class="md-layout md-gutter md-alignment-center">
                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <label>Jeu</label>
                      <md-select v-model="object.game_id" name="game">
                        <md-option v-for="game of games" :key="game.id" :value="game.id">
                          {{ game.name }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100">
                    <rule name="price" rules="required" v-slot="{errors}">
                      <md-field>
                        <label>Prix</label>
                        <md-input type="number" name="price" v-model="object.price"/>
                        <span class="input-error">{{ errors[0] }}</span>
                      </md-field>
                    </rule>
                  </div>

                  <div class="md-layout-item md-small-size-100">
                    <rule name="places disponibles" rules="required" v-slot="{errors}">
                      <md-field>
                        <label>Places disponibles</label>
                        <md-input type="number" name="participants" v-model="object.nb_place"/>
                        <span class="input-error">{{ errors[0] }}</span>
                      </md-field>
                    </rule>
                  </div>

                </div>

                <div class="md-layout md-gutter md-alignment-center">
                  <div class="md-layout-item md-small-size-100">
                    <rule name="date" rules="required" v-slot="{errors}">
                      <md-field>
                        <label class="input-date-label">Date</label>
                        <datetime
                          value-zone="Europe/Zurich"
                          :format="dateFormat"
                          type="datetime"
                          input-class="input-datetime"
                          v-model="object.date"
                          auto-continue
                          auto-close
                          placeholder="Sélectionner une date"
                          :phrases="{ok:'ok', cancel:'annuler'}"
                        />
                        <span class="input-error">{{ errors[0] }}</span>
                      </md-field>
                    </rule>
                  </div>

                  <div class="md-layout-item md-small-size-100">
                    <md-field>
                      <label>Image</label>
                      <md-file type="file" accept="image/*" @change="addImage" :value="object.image_path"/>
                      <div>
                        <md-button class="md-icon-button md-accent md-dense" @click="removeImage">
                          <md-icon>delete</md-icon>
                        </md-button>
                        <md-tooltip md-direction="top">effacer l'image</md-tooltip>
                      </div>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout-item md-small-size-100">
                  <md-field>
                    <label>Description</label>
                    <md-textarea v-model="object.description"></md-textarea>
                  </md-field>
                </div>
              </div>

              <md-card-actions>
                <md-button :disabled="disabled" class="md-raised md-accent mr-1" @click="close">
                  Annuler
                </md-button>
                <md-button :disabled="disabled" type="submit" class="md-raised md-primary">
                  Valider
                </md-button>
              </md-card-actions>

            </div>
          </form>
        </validator>
      </md-dialog-content>
    </md-dialog>

    <div class="fab-space"/>
  </div>
</template>

<script>
import EventService from '@/components/event/EventService';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/fr.json';
import { mapGetters } from 'vuex';
import { Datetime } from 'vue-datetime';
import { sqlDateTimeFormat } from '@/date-format';
import ParticipantsList from '@/components/event/participant/ParticipantsList.vue';
import formMixin from '@/mixins/form-mixin';
import staticFileMixin from '@/mixins/static-file-mixin';
import UploadButton from '@/components/shared/upload-button/UploadButton.vue';
import { DateTime } from 'luxon';

extend('required', {
  ...required,
  message: messages.required,
});
export default {
  name: 'EventForm',
  data: () => ({
    model: 'Événement',
    service: EventService,
    dateFormat: sqlDateTimeFormat,
    emails: [],
    disabled: false,
  }),
  components: {
    UploadButton,
    ParticipantsList,
    rule: ValidationProvider,
    validator: ValidationObserver,
    Datetime,
  },
  mixins: [staticFileMixin, formMixin],
  computed: {
    ...mapGetters(['games', 'getFirstGameId']),
  },
  methods: {
    getDefaultObject() {
      return {
        game_id: this.getFirstGameId,
        date: DateTime.now().toISO(),
      };
    },
    addUsers(users) {
      this.object.users = users;
      this.disabled = false;
    },
    checkResults() {
      window.open(this.getFile(this.object.result_path), '_blank');
    },
    preventAction() {
      this.disabled = true;
    },
    addImage(event) {
      const [image] = event.target.files;
      const object = { ...this.object, image, image_path: URL.createObjectURL(image) };
      this.$store.dispatch('setObject', object);
    },
    removeImage() {
      const object = { ...this.object, image: null, image_path: '' };
      this.$store.dispatch('setObject', object);
    },
  },
};
</script>

<style scoped>

@media only screen and (min-width: 1575px) {
  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 1575px) {
  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width:30%;
    max-width:30%;
    flex:0 1 30%
  }
}

.mr-1 {
  margin-right: 10px;
}

</style>
