<template>
  <div>
    <md-list-item class="md-layout">
      <div class="md-layout md-alignment-center md-xsmall-size-85">
        <div class="md-layout-item md-xsmall-size-100 md-small-hide">
          <image-item :path="object.image_path" alt="photo of the event"/>
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-list-item-text">
          {{ object.name }}
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          {{ object.date | dateFR }}
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-list-item-text">
          {{ object.game.name }}
        </div>
      </div>

      <div class="md-layout md-layout-item md-size-10 md-small-20 md-xsmall-size-15">
        <div class="btn-edit">
          <md-button @click="onEdit" class="md-icon-button md-dense md-raised md-accent btn-green">
            <md-icon>mode</md-icon>
          </md-button>
        </div>
        <md-button @click="active = true" class="md-icon-button md-dense md-raised md-accent">
          <md-icon>delete</md-icon>
        </md-button>
      </div>

    </md-list-item>

    <md-dialog-confirm
      :md-active.sync="active"
      md-title="Supprimer événement"
      md-content="Vous voulez vraiment supprimer cet événement?"
      md-confirm-text="Confirmer"
      md-cancel-text="Annuler"
      @md-confirm="onConfirm"/>

    <md-divider class="md-full"/>
  </div>
</template>

<script>

import EventService from '@/components/event/EventService';
import translationMixin from '@/mixins/translation-mixin';
import itemMixin from '@/mixins/item-mixin';
import ImageItem from '@/components/shared/image-item/ImageItem.vue';

export default {
  name: 'EventItem',
  data: () => ({
    active: false,
    service: EventService,
  }),
  components: {
    ImageItem,
  },
  mixins: [itemMixin, translationMixin],
};

</script>
